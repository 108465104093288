import { DashboardContainer } from "@/router/components";
import validateComponent from "@/router/middleware/validateComponent";

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard');

export default [
  {
    meta: {
      breadcrumb: { name: 'dashboard' },
      dictKey: 'DashboardContainer',
      menuId: 100,
    },
    path: '/dashboard',
    component: DashboardContainer,
    children: [
      {
        meta: {
          component: 'dshbrd',
          middleware: [validateComponent],
          breadcrumb: { name: 'dashboard' },
          dictKey: 'Dashboard',
          menuId: 101,
        },
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
    ],
  },
];
